<template>
  <div>
    <HeaderPanel
      title="Brand"
      :hasFilter="false"
      :filter="filter"
      placeholder="Search Name, Telephone"
      @search="filterAction"
      :hasDropdown="true"
      routerPath="/setting/brand/0"
    >
      <template v-slot:content-dropdown>
        <b-dropdown-item @click="showModalExport()">
          <span>Export Brand Sales Summary </span>
        </b-dropdown-item>
      </template>
    </HeaderPanel>

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isLoading"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(action)="{ item }">
          <div class="d-flex justify-content-center">
            <router-link :to="'/setting/brand/' + item.id">
              <b-button variant="link" class="text-warning px-1 py-0">
                <font-awesome-icon icon="pencil-alt" title="Edit" />
              </b-button>
            </router-link>
          </div>
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>
    <ModalExportTemplate
      ref="ModalExportTemplate"
      title="Export Brand Sales Summary"
    />
  </div>
</template>

<script>
import ModalExportTemplate from "@/components/report/transaction/ModalExportTemplate";
export default {
  components: {
    ModalExportTemplate,
  },

  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "name",
          label: "Name",
          thClass: "w-2",
        },
        {
          key: "address",
          label: "Address",
          thClass: "w-5",
        },
        {
          key: "tel",
          label: "Telephone",
          thClass: "w-2",
        },
        {
          key: "action",
          label: "",
          class: "w-1",
        },
      ],
      items: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
    };
  },

  async mounted() {
    await this.getListBrand();
  },
  watch: {},
  destroyed() {},
  methods: {
    clearValue() {},
    async getListBrand() {
      this.isLoading = true;

      const res = await this.axios.post(`/Brand/List`, this.filter);
      if (res.data.result === 1) {
        this.items = res.data.detail.brands;
        this.rows = res.data.detail.totalCount;

        this.isLoading = false;
      }
    },
    async filterAction() {
      this.filter.page = 1;
      this.isLoading = true;
      const res = await this.axios.post(`/Brand/List`, this.filter);
      if (res.data.result === 1) {
        this.items = res.data.detail.brands;
        this.rows = res.data.detail.totalCount;

        this.isLoading = false;
      }
      this.isLoading = false;
    },

    handleChangeTake(value) {
      this.filter.take = value;
      this.getListBrand();
    },
    pagination(page) {
      this.filter.page = page;
      this.getListBrand();
    },

    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
      };
    },

    showModalExport() {
      const data = {
        ...this.filter,
        apiUrl: "/export_transaction_summary_brand",
      };
      this.$refs.ModalExportTemplate.show(data);
    },
  },
};
</script>

<style scoped></style>
